import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ActivatedRoute, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MobileWidthCheckerService } from '@app/services/mobile-width-checker.service';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, RouterModule, CommonModule, TranslateModule]
})
export class BreadcrumbComponent {
  breadcrumbs$: Observable<MenuItem[]>;
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  currentLang!: string;
  breadcrumbs: MenuItem[] = [];

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private mobileWidthCheckerService: MobileWidthCheckerService,
    public translate: TranslateService,
    private route : ActivatedRoute
  ) {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$.pipe(
      map(breadcrumbs => breadcrumbs?.map(crumb => (
        {
          label: crumb.label,
          url: crumb.url
        })) || [])
    );

    this.breadcrumbs$.subscribe(data => {
      this.breadcrumbs = data;
    });

    this.currentLang = this.translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });
    console.log('route', route)
  }

  goBack(): void {
    window.history.back();
  }
}