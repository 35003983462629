import { UntypedFormGroup } from '@angular/forms';

export class Helper {
  constructor() { }

  static GetFormDataFromFormGroup(form: UntypedFormGroup) {
    const keys = Object.keys(form.value);
    const formData = new FormData(); // Currently empty
    keys.map(key => {
      // for (const key of keys) {
      if (form.value[key]) {
        if (key === 'photo') {
          formData.append('photo', form.value[key], form.value[key].name);
          return undefined;
        }
        if (key === 'photos') {
          for (let i = 0; i < form.value.photos.length; i += 1) {
            formData.append('photos', form.value.photos[i], form.value.photos[i].name);
          }
          return undefined;
        }


        if (key === 'empty_image_placeholder_url') {
          formData.append('empty_image_placeholder_url', form.value[key], form.value[key].name);
          return undefined;
        }

        if (key === 'start_date' || key === 'end_date') {
          const date = new Date(form.value[key]);
          const d = date.toISOString();

          formData.append(key, d);
          return undefined;
        }
        if (typeof form.value[key] === 'object') {
          const Nested = JSON.stringify(form.value[key]);
          formData.append(key, Nested);
          return undefined;
        }
        formData.append(key, form.value[key]);
      }
      return undefined;
    });
    return formData;
  } // end of GetFormData

  static GetValuesFromFormGroup(form: UntypedFormGroup) {
    const keys = Object.keys(form.value);
    const values: { [key: string]: any } = {}; // Currently empty
    keys.map(key => {
      if (form.value[key]) {
        if (key === 'onefile' && form.value[key]) {
          // continue; // skip
          return true;
        }
        if (key === 'file') {
          for (let i = 0; i < form.value.file.length; i += 1) {
          }
          return true;
        }
        if (typeof form.value[key] === 'object') {
          const Nested = JSON.stringify(form.value[key]);
          values[key] = Nested;
          // continue;
          return true;
        }
        const value = form.value[key];
        values[key] = value;
        return true;
      }
      return false;
    });
    return values;
  } // end of GetFormData

}