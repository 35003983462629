import { PrimeNGConfig } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private primengConfig: PrimeNGConfig
  ) {
    translate.addLangs(['en', 'ar']);
    this.primengConfig.ripple = true;
  }

  ngOnInit() {
  }

  title = 'nugttah-partner-panel';
}