import { RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';

export const sidebarItems: MenuItem[] = [
  {
    label: 'dashboardTitle',
    img: 'assets/icons/home-line.svg',
    icon: 'home-line',
    sub_items: [
      { label: 'currentHealthCheck', routerLink: '/statistics/current-health-check/', allowFilter: true },
      { label: 'businessHealth', routerLink: '/statistics/business-health/', allowFilter: true },
      { label: 'loyalty', routerLink: '/statistics/loyalty/', allowFilter: true },
      { label: 'custInsights', routerLink: '/statistics/cust-insights/', allowFilter: true },
      { label: 'menuEngineering', routerLink: '/statistics/menu-engineering/', allowFilter: true },
      { label: 'branchesAnalysis', routerLink: '/statistics/branches-analysis/', allowFilter: true }
    ]
  },
  {
    label: 'brand',
    img: 'assets/icons/store.svg',
    icon: 'store',
    sub_items: [
      { label: 'brandName', routerLink: '/brand', allowFilter: false },
      { label: 'branches', routerLink: '/branches', allowFilter: false },
      { label: 'cashiers', routerLink: '/cashiers', allowFilter: false },
      { label: 'giftCards', routerLink: '/gift-card', allowFilter: false },
      { label: 'payouts', routerLink: '/payouts', allowFilter: false },
      { label: 'generatePayment', routerLink: '/payment-invoices', allowFilter: false }
    ]
  },
  {
    label: 'loyalty',
    img: 'assets/icons/award-03.svg',
    icon: 'award-03',
    sub_items: [
      { label: 'pointsRules', routerLink: '/points-rules', allowFilter: false },
      { label: 'pointsRewards', routerLink: '/rewards', allowFilter: false },
      { label: 'stampCards', routerLink: '/stamps', allowFilter: false },
      { label: 'appleWallet', routerLink: '/apple-wallet/add', allowFilter: false }
    ]
  },
  {
    label: 'reports',
    img: 'assets/icons/file.svg',
    icon: 'file',
    sub_items: [
      { label: 'customers' },
      { label: 'orders' },
      { label: 'ordersByNugttah' },
      { label: 'tablesOrders' },
      { label: 'franchise' },
      { label: 'sms' }
    ]
  },
  {
    label: 'products',
    img: 'assets/icons/package.svg',
    icon: 'package',
    sub_items: [
      { label: 'productCategory', routerLink: '/product-category', allowFilter: false },
      { label: 'products', routerLink: '/products', allowFilter: false }
    ]
  },
  {
    label: 'syncPos',
    img: 'assets/icons/refresh-ccw-05.svg',
    icon: 'refresh-ccw-05',
    sub_items: [
      { label: 'syncFoodics', routerLink: '/sync-foodics', allowFilter: false },
      { label: 'syncMarn', routerLink: '/sync-marn', allowFilter: false },
      { label: 'syncRetm', routerLink: '/sync-retm', allowFilter: false },
      { label: 'syncOdoo', routerLink: '/sync-odoo', allowFilter: false }
    ]
  }
];