import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpLoaderFactory } from './translate-loader';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorService } from './interceptor/main-interceptor';
import { MessageService } from 'primeng/api';
import { AdminAuthService } from './services/admin-auth.service';
import { ToastModule } from 'primeng/toast';
import { CONSTANTS, constants } from './constants/constants';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ToastModule,
    LayoutModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  providers: [
    MessageService,
    AdminAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    { provide: CONSTANTS, useValue: constants }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }