import * as Sentry from '@sentry/angular';
import { environment } from '@env/environment';
import { ErrorHandler, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() { }

  handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
    console.error(error);
  }

  setUserScope(user: { id: string; email: string }) {
    if (!environment.production) {
      return;
    }
    Sentry.setUser({
      id: user.id,
      email: user.email
    });
  }
}