import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private activeThemeSubject = new BehaviorSubject<string>('light-mode');
  activeTheme$ = this.activeThemeSubject.asObservable();

  constructor() {
    const savedTheme = localStorage.getItem('theme') || 'light-mode';
    this.setTheme(savedTheme);
  }

  getTheme(): string {
    return this.activeThemeSubject.value;
  }

  setTheme(theme: string): void {
    document.body.classList.remove(this.activeThemeSubject.value);
    document.body.classList.add(theme);
    this.activeThemeSubject.next(theme);
    localStorage.setItem('theme', theme);
  }

}