import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileWidthCheckerService {
  private mobileScreenSubject = new BehaviorSubject<boolean>(window.innerWidth <= 768);
  isMobileScreen$ = this.mobileScreenSubject.asObservable();

  constructor() {
    this.updateScreenSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateScreenSize();
  }

  private updateScreenSize() {
    this.mobileScreenSubject.next(window.innerWidth <= 768);
  }
}