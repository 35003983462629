import { Injectable, Injector } from '@angular/core';
import { BasicService } from './lib/Basic.Service';
import { config } from '@app/models/Config';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends BasicService<config<any>> {

  brandsBaseApiUrl = `${environment.API_URL}brands`;

  constructor(protected override injector: Injector) {
    super('brands/', injector);
  }

  getBrandProductsWithModifires() {
    const endpoint = 'brands/menu';
    return this.Get(this.ReturnType, endpoint, '');
  }

  brandDetails(id: string): Observable<any> {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}`).pipe(
      map((resp: any) => resp.data.brands)
    );
  }
  syncAll(data: any): Observable<any> {
    return this.http.post(`${this.brandsBaseApiUrl}/sync-tables`, data);
  }

  updateBrand(data: object) {
    return this.http.put(`${this.brandsBaseApiUrl}/partner/update`, data);
  }

  getMarnConfigurations(id: string) {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}/marn/config`);
  }

  configureMarn(id: string, formData: { get: (arg0: string) => any; }) {
    const data = {
      hid: formData.get('marnBrandKey')
    };
    return this.http.put(`${this.brandsBaseApiUrl}/${id}/marn/config`, data);
  }

  getMarnLocations(id: string) {
    return this.http.get(`${this.brandsBaseApiUrl}/${id}/marn/locations`);
  }

  // get brand tag
  getBrandTag() {
    return this.http.get(`${this.brandsBaseApiUrl}/partner/view-brand-tag`);
  }

  editBrandTag(payload: any) {
    return this.http.put(`${this.brandsBaseApiUrl}/partner/update-brand-tag`, payload);
  }
  viewSubscriptionPackages() {
    return this.http.get(`${this.brandsBaseApiUrl}/partner/view-subscription-packages`);

  }
}