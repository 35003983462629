import { InjectionToken } from '@angular/core';
import { orderStatuses, orderTypes, orderSources } from './order.constants';
import { smsStatuses, smsReasons } from './sms.constants';
import { voucherStatuses, voucherTypes, voucherManualTypes } from './voucher.constants';
import { paymentOptions } from './payment.constants';
import { pageSizeOptions } from './list-page-size-options.constants';
import { loadingStatesConfig } from './loading-states-config.constants';

export const constants = {
  smsStatuses,
  smsReasons,
  orderStatuses,
  orderTypes,
  orderSources,
  voucherStatuses,
  voucherTypes,
  voucherManualTypes,
  paymentOptions,
  pageSizeOptions,
  loadingStatesConfig
};

export type Constants = typeof constants;

export const CONSTANTS = new InjectionToken<Constants>('constants');