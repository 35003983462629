import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Injector, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { GenricService } from './Generic.service';
import { Helper } from './Helper.service';
export class BasicService<T> extends GenricService {
  BaseEndPoint: string;

  protected ReturnType!: T;

  public _fb: UntypedFormBuilder;

  helper: Helper;

  protected override messageService: MessageService;

  constructor(endpoint: string, protected override injector: Injector) {
    super(injector);

    this.BaseEndPoint = endpoint;
    this.helper = new Helper();
    this._fb = injector.get(UntypedFormBuilder);
    this.messageService = injector.get(MessageService);
  }
  /** List All */

  GetAll(filter: any) {
    return this.Get(this.ReturnType, this.BaseEndPoint, filter);
  }

  /** Get One Element */
  GetOne(id: Number, filter = ''): Observable<any> {
    return this.Get(this.ReturnType, `${this.BaseEndPoint}${id}`, filter);
  }
  /**  Add On */
  AddOneWithUpload(form: UntypedFormGroup) {

    const formData = Helper.GetFormDataFromFormGroup(form);

    const endpoint = `${this.BaseEndPoint}add/`;
    return this.Post(this.ReturnType, endpoint, formData);
  }

  /**  Add On without upload */
  addOne(form: UntypedFormGroup) {
    // return true;
    const Data = Helper.GetFormDataFromFormGroup(form);
    return this.Post(this.ReturnType, this.BaseEndPoint, Data);
  }

  /**  Update One */
  UpdateOne(id: string, form: UntypedFormGroup) {
    const Data = Helper.GetFormDataFromFormGroup(form);
    (JSON.stringify(Data));

    /** form.value because it not contain file or multipart */
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Put(this.ReturnType, endpoint, Data);
  }


  UpdateNotForm(id: string, Data: any) {
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Put(this.ReturnType, endpoint, Data);
  }

  /**  Delete One */
  DeleteOne(id: string) {
    const endpoint = `${this.BaseEndPoint}${id}`;
    return this.Delete(this.ReturnType, endpoint);
  }

  changeStatus(id: any, status: any) {
    const data = {
      action: 'block',
      status
    };
    const endpoint = `${this.BaseEndPoint}${id}/change_profile_status`;
    return this.Put(this.ReturnType, endpoint, data);
  }

  override toastrSuccess(msg: any) {
    this.messageService.add({ severity: 'success', summary: msg });

  }

  inializeForm(fieldValues: { [key: string]: any; }): UntypedFormGroup {
    return this._fb.group(fieldValues);
  }

  override toastrError(msg: any) {
    this.messageService.add({ severity: 'error', summary: msg });
  }

  override toastrInfo(msg: any) {
    this.messageService.add({ severity: 'info', summary: msg });
  }

  override toastrWarn(msg: any) {
    this.messageService.add({ severity: 'warn', summary: msg });
  }
}