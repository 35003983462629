export const orderStatuses = [
  { label: 'Pending', value: 1 },
  { label: 'Active', value: 2 },
  { label: 'Void', value: 3 },
  { label: 'Done', value: 4 },
  { label: 'Canceled', value: 5 }
];

export const orderTypes = [
  { label: 'Dine in', value: 1 },
  { label: 'Take away', value: 2 },
  { label: 'Pickup', value: 3 },
  { label: 'Delivery', value: 4 },
  { label: 'Drive thru', value: 5 }
];

export const orderSources = [
  { label: 'Cashier', value: 1 },
  { label: 'Call center', value: 2 },
  { label: 'API', value: 3 },
  { label: 'Waiter', value: 4 },
  { label: 'Foodics', value: 5 }
];