// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-container ::ng-deep .p-toolbar {
  background-color: var(--secondary-background);
  border: none;
  padding-top: 32px;
}
.topbar-container .p-toolbar-group-end .switcher-language {
  cursor: pointer;
}
.topbar-container .p-toolbar-group-end .switcher-language p {
  color: var(--primary-color);
  font-weight: 400px;
  line-height: 20px;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info {
  height: 64px;
  border-radius: 16px;
  padding: 16px;
  background-color: var(--primary-background-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info .nugttah-image {
  width: 48px;
  height: 48px;
  border-radius: 12px;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep p-overlay.p-element {
  position: absolute;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown .p-icon {
  color: var(--primary-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-label {
  color: var(--primary-color);
  padding-right: 0px;
  font-weight: 500;
  line-height: 24px;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-inputwrapper-focus {
  border: none;
  outline: none;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info .p-dropdown .p-dropdown-panel {
  background-color: var(--primary-background-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown .p-dropdown-panel {
  background-color: var(--primary-background-color);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  left: 5px;
  transform: translate(-50%, 10%);
  z-index: 1000;
  pointer-events: auto;
  box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus,
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background-color: transparent !important;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus:hover,
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--sidebar-bg-hover) !important;
  color: var(--sidebar-dropdown-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus:hover p,
.topbar-container .p-toolbar-group-end .bo-dropdown-info ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover p {
  color: var(--sidebar-dropdown-color);
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info .label {
  cursor: pointer;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.topbar-container .p-toolbar-group-end .bo-dropdown-info .label p {
  padding: 8px;
  border-radius: 8px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .topbar-container ::ng-deep .p-toolbar {
    padding: 10px !important;
  }
  .topbar-container .p-toolbar-group-end .bo-dropdown-info {
    height: 48px !important;
    width: 48px;
  }
  .topbar-container .p-toolbar-group-end .bo-dropdown-info .mobile-dropdown ::ng-deep .p-menu.p-menu-overlay {
    background-color: var(--primary-background-color);
    border: none;
  }
  .topbar-container .p-toolbar-group-end .bo-dropdown-info .mobile-dropdown ::ng-deep .p-menu .p-menuitem > .p-menuitem-content:hover {
    background-color: var(--sidebar-bg-hover);
  }
  .topbar-container .p-toolbar-group-end .bo-dropdown-info .mobile-dropdown ::ng-deep .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: var(--secondary-color);
  }
  .topbar-container .p-toolbar-group-end .bo-dropdown-info .mobile-dropdown ::ng-deep .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text:hover {
    color: var(--sidebar-dropdown-color);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
