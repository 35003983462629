export const smsStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Sent', value: 'sent' },
  { label: 'Failed', value: 'failed' }
];

export const smsReasons = [
  { label: 'Order Pickup', value: 'order_pickup' },
  { label: 'Order Ready', value: 'online_order_ready' },
  { label: 'Monitor Rule', value: 'monitor_rule' },
  { label: 'New Point', value: 'new_points' }
];