// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-section {
  /* Add a slash symbol (/) before/behind each list item */
}
.breadcrumb-section .breadcrumb-container {
  color: var(--secondary-color);
  padding: 0px 20px;
}
.breadcrumb-section .breadcrumb-container a {
  color: inherit;
  text-decoration: none;
}
.breadcrumb-section .breadcrumb-container a h4 {
  font-weight: normal;
}
.breadcrumb-section i {
  margin: 0 10px;
}
.breadcrumb-section ul.breadcrumb {
  padding: 0px;
  list-style: none;
  margin: 13px 10px;
  display: flex;
}
.breadcrumb-section ul.breadcrumb li {
  display: inline;
  display: flex;
  align-items: center;
}
.breadcrumb-section ul.breadcrumb li h3 {
  font-weight: 600;
  position: relative;
  top: 18px;
  transform: translate(0%, -50%);
}
.breadcrumb-section ul.breadcrumb li + li:before {
  padding: 8px;
  color: var(--secondary-color);
  content: ">";
}
.breadcrumb-section .active-link {
  color: var(--primary-color);
}
@media screen and (max-width: 768px) {
  .breadcrumb-section .breadcrumb-container {
    padding: 0 10px;
  }
  .breadcrumb-section .breadcrumb-container .col-12.breadcrumb-col-section {
    position: relative;
    top: 5px;
    right: 20px;
    left: 20px;
    transform: translate(0%, 0%);
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb {
    margin: 0;
    position: relative;
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb li {
    position: relative;
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb li a > h4 {
    position: relative;
    top: 0;
    transform: translate(0%, 0);
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb li h3 {
    position: relative;
    top: 0;
    transform: translate(0%, 0);
    white-space: nowrap;
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb li h3 ::ng-deep .pi {
    font-size: 14px;
    margin: 0px 2px;
  }
  .breadcrumb-section .breadcrumb-container ul.breadcrumb li + li:before {
    padding: 0 8px;
    content: "" !important;
    display: none;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
