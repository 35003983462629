import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { MobileWidthCheckerService } from '../../services/mobile-width-checker.service';
import { AdminAuthService } from '@services/admin-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  profileDropdown: MenuItem[] = [
    {
      label: this.translate.instant('profile'),
      command: (event) => this.onMenuItemSelect(event)
    },
    {
      label: this.translate.instant('logOut'),
      command: (event) => this.onMenuItemSelect(event)
    }
  ];
  currentLang: string;
  selectedCountry: string | undefined;
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  showDropdown = false;
  selectedDropdownItem = { label: '' };
  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AdminAuthService,
    private mobileWidthCheckerService: MobileWidthCheckerService
  ) {
    this.currentLang =
      localStorage.getItem('language') || translate.getDefaultLang() || 'en';
  }
  ngOnInit() {
    this.switchLanguage(this.currentLang);
  }

  switchLanguage(language: string) {
    this.currentLang = language;
    this.translate.use(language);
    this.profileDropdown = [
      {
        label: this.translate.instant('profile'),
        command: (event) => this.onMenuItemSelect(event)
      },
      {
        label: this.translate.instant('logOut'),
        command: (event) => this.onMenuItemSelect(event)
      }
    ];
    this.translate.setDefaultLang(language);
    localStorage.setItem('language', language);
    const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
    if (html) {
      const langDir = language === 'ar' ? 'rtl' : 'ltr';
      html.classList.remove('rtl', 'ltr');
      html.dir = langDir;
      html.classList.add(langDir);
    }
  }

  onMenuItemSelect(event: any) {
    if (event.item.label === 'logOut') {
      this.logOut();
    }
  }
  onDropdownChange(event: any) {
    if (this.selectedDropdownItem.label === 'logOut') {
      this.logOut();
    }
  }
  logOut() {
    this.authService.LogOut().subscribe(config => {
      window.location.reload();
      return this.router.navigate(['/']);
    });
  }
}