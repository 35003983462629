

export const loadingStatesConfig = {
  defaultErrorMsg: 'Oooops Something went wrong! Please try again',
  actionBtnText: 'Reload',
  sizes: {
    large: 100,
    medium: 75,
    small: 50
  },
  theme: 'primary'
};