const pageSizeOptionsMap = {
  normal: 10,
  large: 30,
  xLarge: 100
};

const pageSizeOptionsArr = Object.values(pageSizeOptionsMap);

export const pageSizeOptions = {
  pageSizeOptionsMap,
  pageSizeOptionsArr
};