import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminAuthService } from '@services/admin-auth.service';
import { SpinnerService } from '@app/services/spinner.service';
import { MobileWidthCheckerService } from '@services/mobile-width-checker.service'


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  isSidebarOpen = true;
  isAuthenticated = false;
  currentLang: string = 'en';
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  loading = false;
  constructor(
    public translate: TranslateService,
    private adminService: AdminAuthService,
    private spinnerService: SpinnerService,
    private mobileWidthCheckerService: MobileWidthCheckerService
  ) { }
  ngOnInit() {
    this.currentLang = this.translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });
    const user = JSON.parse(localStorage.getItem('User') as string);
    if (user != null) {
      this.isAuthenticated = true;
    }
    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });
    this.spinnerService.spinnerState$.subscribe((state: boolean) => {
      this.loading = state;
    });
  }
  checkShowNps() {
    // this.npsService.checkIfPartnerShouldRate().subscribe(
    //   (response: any) => {
    //     if (response.data.businessShouldRate) {
    //       this.showNps = true;
    //     }
    //   }
    // );
  }
  onSidebarStateChange(state: string) {
    this.isSidebarOpen = (state === 'expanded');
  }
}