import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { LayoutComponent } from './layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SvgIconComponent } from '../shared/svg-icon/svg-icon.component';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    TopbarComponent,
    LayoutComponent,
    SidebarComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ButtonModule,
    SidebarModule,
    ToolbarModule,
    DropdownModule,
    AccordionModule,
    TranslateModule,
    SvgIconComponent,
    InputSwitchModule,
    SplitButtonModule,
    MenuModule,
    ToastModule,
    ProgressSpinnerModule,
    BreadcrumbComponent
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }